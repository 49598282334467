import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import Facebook from '../common/Facebook';
import Instagram from '../common/Instagram';
import Linkedin from '../common/Linkedin';
import { AddressDetails, SocialLink } from '../Footer';

const Footer = ({ footerImg, footerEitImg }) => (
	<footer className="bg-white">
		<div className="container">
			<div className="row pt-5">
				<div className="col-6 col-md-3">
					<p className="font-weight-bolder mb-4">Rólunk</p>
					<p className="small text-secondary mb-3">
						<Link to="/courier" className="text-secondary text-underline">
							Futároknak
						</Link>
					</p>
					<p className="small text-secondary mb-3">
						<a
							className="text-secondary text-underline"
							href="http://blog.viddl.hu"
							target="_blank"
							rel="noopener noreferrer"
						>
							Blog
						</a>
					</p>
					<p className="small mb-3">
						<a href="/policy.pdf" className="text-secondary text-underline">
							Adatvédelmi nyilatkozat
						</a>
					</p>
					<div className="mb-3">
						<SocialLink
							href="https://www.facebook.com/viddlhungary"
							label="Facebook"
						>
							<Facebook />
						</SocialLink>
						<SocialLink
							href="https://www.linkedin.com/company/viddl"
							label="Linkedin"
						>
							<Linkedin />
						</SocialLink>
						<SocialLink
							href="https://www.instagram.com/viddl_"
							label="Instagram"
							isLast
						>
							<Instagram />
						</SocialLink>
					</div>
				</div>
				<div className="col-6 col-md-3">
					<AddressDetails
						title="Elérhetőségeink"
						address="1054 Budapest, Széchenyi utca 1."
						href="https://www.google.com/maps/place/ViddL/@47.5025502,19.0448318,18z/data=!4m13!1m7!3m6!1s0x4741dc16807cf8c5:0x6d8758ce461db39c!2sBudapest,+Széchenyi+u.+1,+1054!3b1!8m2!3d47.502543!4d19.0461649!3m4!1s0x4741ddbacf4151a9:0xef13a1e7bdf35bcb!8m2!3d47.5025574!4d19.0457574"
						mobile="+36 70 758 2536"
						email="budapest@viddl.hu"
						support="H-P 09:00-17:00"
					/>
				</div>
				<div className="col-6 col-md-6 text-right">
					<Img fixed={footerEitImg.childImageSharp.fixed} />
				</div>
				<div className="col-12 text-right">
					<Link to="/" aria-label="ViddL">
						<Img fixed={footerImg.childImageSharp.fixed} />
					</Link>
				</div>
				<div className="col-12 pb-3">
					<p className="small">© {new Date().getFullYear()} Cargo-Viszed Kft</p>
				</div>
			</div>
		</div>
	</footer>
);

export default Footer;
