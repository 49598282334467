import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import ChevronRight from '../common/ChevronRight';
import './Hero.scss';

const Hero = ({ images }) => {
	const { david_hero } = images;

	return (
		<section id="hero" className="bg-primary text-center pb-0 pt-5 pt-md-2">
			<div className="container mb-5">
				<div className="row">
					<div className="col-12 col-md-6 order-md-1">
						<h1 className="mb-4 text-lg-left">
							Csatlakozz a <span className="text-white">ViddL futár</span>{' '}
							közösséghez!
						</h1>
						<h4 className="mb-5 mb-md-4 font-weight-normal text-lg-left pb-lg-5">
							Kötetlen munka, akár 2500 Ft/óra kereset, rengeteg meló{' '}
							<u>Budapest</u>, <u>Győr</u>, <u>Debrecen</u>, <u>Kecskemét</u>,{' '}
							<u>Székesfehérvár</u>, <u>Nyíregyháza</u>, <u>Szeged</u> és{' '}
							<u>Miskolc</u> belvárosában. Pénzre váltjuk szabadidődet!
						</h4>
						<div className="mt-lg-3 text-lg-left">
							<Link
								to="/courier"
								className="btn btn-primary btn-lg my-1"
								id="apply"
								aria-label="Jelentkezek futárnak"
							>
								Jelentkezek futárnak
								<ChevronRight />
							</Link>
						</div>
					</div>
					<div className="col-12 col-md-6 order-md-0 pt-md-5 pt-lg-0">
						<Img
							fluid={david_hero.childImageSharp.fluid}
							alt="Csatlakozz a ViddL futár közösséghez!"
							style={{
								maxWidth: 500,
								width: '70%',
								marginLeft: 'auto',
								marginRight: 'auto',
							}}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Hero;
